<template>
    <Layout :footer="false" :main-class-map="mapType === 'simple' ? 'py-10' : 'py-10 mx-4 lg:mx-0'">
        <template #body v-if="!trialPlanIsExriped">
            <div class="wrap-button" v-show="step === PRESIZE_STEP">
                <button
                    :class="['text-theme-32 up-down-button up-button', {'slide-from-top-enter-active': step === PRESIZE_STEP && showUpButton}]"
                    id="upButton"
                    @click="up"
                >
                    <span>❯</span>
                </button>
                <button
                    :class="['text-theme-32 up-down-button down-button', {'slide-from-bottom-enter-active': step === PRESIZE_STEP && showDownButton}]"
                    id="downButton"
                    @click="down"
                >
                    <span>❮</span>
                </button>
            </div>

            <SimplePage v-if="mapType === 'simple'" />

            <section v-else-if="mapType === 'quote'" class="container font-dm-sans" id="container">
                <div
                    :style="`transform: translateY(${stepTransformData.stepTransform}%);  transition: all 0.5s ease;`"
                    id="quote-transition"
                    class="transition"
                >
                    <ContactStep
                        :class="[{'active': step === CONTACT_STEP}, 'slide-form-item']"
                        :active="step === CONTACT_STEP"
                        :tabIndex="step === CONTACT_STEP ? 0 : -1"
                        :key="'QuoteSliderForm' + 1"
                    />
                    <OptionStep
                        :class="[{'active': step === OPTION_STEP }, 'slide-form-item']"
                        :active="step === OPTION_STEP"
                        :tabIndex="step === OPTION_STEP ? 0 : -1"
                    />
                    <PresizeStep
                        :class="[{'active': step === PRESIZE_STEP }, 'slide-form-item']"
                        :active="step === PRESIZE_STEP"
                        :tabIndex="step === PRESIZE_STEP ? 0 : -1"
                    />
                    <SummaryStep
                        :class="[{'active': step === SUMMARY_STEP}, 'slide-form-item']"
                        :tabIndex="step === SUMMARY_STEP ?  0 : -1"
                        :active="step === SUMMARY_STEP"
                        :key="'QuoteSliderForm' + 4"
                    />
                </div>
            </section>
        </template>
      <template #body v-else>
        <div class="error-msg">
          <p>Sorry this page is not currently active. Please reach out to your contractor for questions.</p>
        </div>
      </template>
    </Layout>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, onBeforeMount, watch, defineAsyncComponent } from "vue";
import { useStore } from 'vuex';
import axios from "axios";
import { CONTACT_STEP, PRESIZE_STEP, OPTION_STEP, SUMMARY_STEP } from "@scripts/store/quote/quoteConstants";

import ContactStep from '../Steps/ContactStep.vue';
const OptionStep = defineAsyncComponent(() => import('../Steps/OptionStep.vue'));
const PresizeStep = defineAsyncComponent(() => import('../Steps/PresizeStep.vue'));
const SummaryStep = defineAsyncComponent(() => import('../Steps/SummaryStep.vue'));
const SimplePage = defineAsyncComponent(() => import('../Steps/SimpleStep.vue'));

import Layout from "../Layouts/Layout.vue";
import '@assets/sass/quote.scss';


const store = useStore();
const step = computed(() => store.getters['quote/step']);
const selectedTypeId = computed(() => store.getters['quote/selectedTypeId']);
const stepTransformData = computed(() => store.getters['quote/getTransformData']);
const mapType = ref('');
const showUpButton = ref(false);
const showDownButton = ref(true);
const trialPlanIsExriped = computed(() => store.getters['auth/getTrialPlanIsExpired']);
const website = computed(() => store.getters['quote/website']);

const handleTab = (e) => {
    if (
        (e.key === 'Tab' && (e.target.classList.contains('back-button') || e.target.classList.contains('next-button') && !selectedTypeId.value))
        || (e.key === 'Enter' && step.value === OPTION_STEP)
    ) {
        e.preventDefault();
    }
}

const setPercentage = (height) => {
    let percentage;

    switch (true) {
        case height > 700 && height <= 800:
            percentage = 18;
            break;
        case height > 600 && height <= 700:
            percentage = 21;
            break;
        case height > 500 && height <= 600:
            percentage = 24;
            break;
        case height > 400 && height <= 500:
            percentage = 27;
            break;
        case height > 300 && height <= 400:
            percentage = 40;
            break;
        case height <= 300:
            percentage = 53;
            break;
        default:
            percentage = 15;
            break;
    }

    return percentage;
}

const setHeightSlideFormItem = async () => {
    const baseHeight = window.innerHeight;
    const slideFormItem = document.querySelectorAll('.slide-form-item');
    const percentage = setPercentage(baseHeight);
    const height = Number(baseHeight - (baseHeight * percentage / 100).toFixed()) + 25;

    slideFormItem.forEach(slide => {
        slide.style.height = `${ height }px`;
    });

    await store.commit('quote/setStepHeight', height);
    await store.commit('quote/setWindowSize', { height: window.innerHeight, width: window.innerWidth });
}

const up = () => {
    const container = document.getElementById('slideItemMap');
    if (!container) return
    const section = document.getElementById('mobile-control-panel');

    const sectionRect = section.getBoundingClientRect();

    const sectionMiddle = sectionRect.top + sectionRect.height / 2;
    const containerMiddle = container.clientHeight / 2;

    container.scrollTop = (sectionMiddle - containerMiddle) + container.scrollTop;
}

const down = () => {
    const container = document.getElementById('slideItemMap');
    if (!container) return
    container.scrollTop = container.scrollHeight - container.clientHeight;
}


watch(
    () => mapType.value,
    (value) => {
        if (value !== 'simple') {
            store.dispatch('quote/setRanges');
            store.dispatch('quote/setSimpleUnitItems');

            document.body.addEventListener('keydown', handleTab);

            nextTick(() => {
                const itemsInput = document.querySelectorAll('input');

                itemsInput.forEach(element => {
                    element.addEventListener("blur", function () {
                        document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
                    });
                });
            });

            store.commit('quote/clearState');
            // 4 - count of divs inside document.getElementById('quote-transition')
            store.commit('quote/setIndent', 100 / 4);

            window.addEventListener("resize", setHeightSlideFormItem);
        }
    }
);

watch(
    () => step.value,
    (value) => {
        setHeightSlideFormItem();
    }
);


onMounted(async () => {  
    document.body.classList.add('overflow-hidden');
    document.documentElement.classList.add('overflow-hidden');

    const container = document.getElementById('slideItemMap')
    if (!container) return
    container.addEventListener("scroll", (event) => {
        const target = event.target.documentElement ? event.target.documentElement : event.target;
        const percentScroll = (target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100;

        showDownButton.value = percentScroll <= 45;
        showUpButton.value = percentScroll >= 50;
    });
});

onBeforeMount(() => {
    axios
        .get(`api/get-type-show-map/${window.location.host.split('.')[0]}`)
        .then((response) => {
            mapType.value = response.data;
        });

    
    store.commit('auth/setTrialPlanIsExpired', false);
});
</script>

<style scoped lang="scss">
.error-msg {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  height: calc(100vh - 180px);
  
  p {
    font-size: 1.5rem;
    text-align: center;
    line-height: 3rem;
  }
}
</style>
